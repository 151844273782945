<template>
  <div class="animated fadeIn container">
    <h1>충전 관리</h1>
    <table class="border-table mt-4" v-if="items.length > 0">
      <thead>
        <tr>
          <td width="100px">요청일</td>
          <td>이메일</td>
          <td width="120px">입금자명</td>
          <td width="120px" class="text-center">입금액</td>
          <td width="180px">상태</td>
        </tr>
      </thead>
      <tbody>
        <tr :key=item.id v-for="item in items">
          <td>
            <span class="mr-3">{{ item.createdAt | dateShortFormat }}</span>
          </td>
          <td>
            {{ item.user.email }}
          </td>
          <td>
            {{ item.depositor }}
          </td>
          <td>
            <span class="float-right mr-2">{{ item.money | numberFormat }} 원</span>
          </td>
          <td class="text-right">

            <button v-if="item.status == 0" class="btn btn-primary" @click="allowDepositRequest(item.id, 2)">승인</button>
            <button v-if="item.status == 0" class="ml-2 btn btn-danger"
              @click="allowDepositRequest(item.id, 1)">거절</button>
            <span v-if="item.status == 1">거절됨</span>
            <span v-if="item.status == 2">승인됨</span>
          </td>
        </tr>
      </tbody>
    </table>
    <b-pagination :total-rows="totalCount" v-model="currentPage" :per-page="limit" :limit="10"
      class="my-3"></b-pagination>
  </div>
</template>

<script>
import DepositService from '@/services/DepositService'
import {
  mapGetters
} from 'vuex'

export default {
  name: 'AdminDepositHistory',
  data() {
    return {
      items: [],
      totalCount: 0,
      currentPage: 1,
      limit: 20
    }
  },
  mounted() {
    document.body.classList.add('bg-white')
    this.getRequests()
  },
  methods: {
    async getRequests() {
      const response = await DepositService.getRequests({
        page: this.currentPage,
        limit: this.limit
      })
      if (response.status == 200) {
        this.items = response.data.list
        this.totalCount = response.data.totalCount
      }
    },
    async allowDepositRequest(requestId, status) {
      if (confirm(this.statusText(status))) {
        const response = await DepositService.updateRequest(requestId, {
          status: status
        })
        if (response.status == 200) {
          location.reload()
        }
      }
    },
    statusText(status) {
      if (status == 1) {
        return '승인을 거절하시겠습니까?'
      } else if (status == 2) {
        return '승인을 하시겠습니까?'
      } else {
        return '알수없음'
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser'
    })
  },
  watch: {
    currentPage: function () {
      this.getRequests()
    }
  }
}

</script>
