import Api from '@/services/Api'

export default {
  createRequest(params) {
    return Api().post('/api/depositRequests', params)
  },

  updateRequest(id, params) {
    return Api().put('/api/depositRequests/' + id, params)
  },

  getRequests(params) {
    return Api().get('/api/depositRequests', {
      params: params
    })
  }
}
